import CartProductList from '../../Organisms/ProductList/CartProductList';
import { styled } from '../../stitches.config';
import { GetCart } from './Cart';
import ICartPage from '../../Cart/Models/CartPageModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { CheckoutSectionTitle } from '../Checkout/CheckoutProductListing';
import CartPriceSummary from '../Checkout/CartPriceSummary';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import CtaButton from '../../Atoms/Buttons/CtaButton';
import useMedia from '../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';

function CartPage() {
  const { pageHeading } = useCurrentPage<ICartPage>();

  const { cart, isLoading } = GetCart();
  const {
    'common/loading': loading,
    'common/goToCheckout': goToCheckout,
    'common/emptyCart': emptyCartLabel,
  } = useTranslationData();
  const {
    staticPages: { checkoutPage },
  } = useAppSettingsData();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  return (
    <Main>
      {isLoading && <div>{loading}</div>}
      {!isLoading && (
        <CartContainer>
          <CartContent>
            <CheckoutSectionTitle noMargin={!isDesktop}>
              {cart && !!cart.lineItems.length ? pageHeading : emptyCartLabel}
            </CheckoutSectionTitle>
            <CartProductList
              products={cart.lineItems}
              isLoading={false}
              allowEdit={true}
              isMiniCart={false}
            />
            {cart && !!cart.lineItems.length && (
              <>
                <CartPriceSummary cart={cart} onCartPage />
                <ButtonWrapper>
                  <CtaButton href={checkoutPage} type={'secondary'}>
                    {goToCheckout}
                  </CtaButton>
                </ButtonWrapper>
              </>
            )}
          </CartContent>
        </CartContainer>
      )}
    </Main>
  );
}

const CartContainer = styled('div', {
  wh: '100%',
  justifyContent: 'center',
  p: 4,
  mx: 'auto',
  '@mediaMinLarge': {
    py: 8,
    mx: 'auto',
  },
  maxW: '$contentMaxWidthSmall',
});

const CartContent = styled('div', {
  color: '$cartContentTextPrimary',
  backgroundColor: '$white',
  p: 4,
  mt: 0,
  '@mediaMinLarge': {
    p: 8,
  },
});

const Main = styled('main', {
  backgroundColor: '$cartMainBackgroundPrimary',
});

const ButtonWrapper = styled('div', {
  maxW: 88,
  mt: 8,
  mx: 'auto',
});

export default CartPage;
