import { styled } from '../../stitches.config';

type PropTypes = {
  price: string;
  loweredPrice?: number;
  justifyRight?: boolean;
};

function PriceInfo({ loweredPrice, price, justifyRight }: PropTypes) {
  return (
    <Wrapper>
      {loweredPrice ? (
        <LoweredPrice>
          <PriceText onSale={loweredPrice ? true : false} right={justifyRight}>
            {loweredPrice}
          </PriceText>
          <OldPrice>{price}</OldPrice>
        </LoweredPrice>
      ) : (
        <PriceText right={justifyRight}>{price}</PriceText>
      )}
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const PriceText = styled('span', {
  opacity: 0.9,
  fs: 6,
  fontWeight: 'bold',
  variants: {
    onSale: {
      true: {
        color: '$alert600',
      },
    },
    right: {
      true: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  },
});

const LoweredPrice = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const OldPrice = styled('span', {
  opacity: 0.9,
  fontSize: '10px',
  fontWeight: 'bold',
  color: '$textPrimary',
  textDecoration: 'line-through',
});

export default PriceInfo;
