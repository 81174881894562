import CartItem from '../CartItem/CartItem';
import CartItemMobile from '../CartItem/CartItemMobile';
import IKexVariation from '../../KexVariation/Models/VariationModel.interface';
import useMedia from '../../Shared/Hooks/useMedia';
import { styled } from '../../stitches.config';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import { TrashClearCartIcon } from '../../Atoms/Icons';
import { useState } from 'react';
import ClearCartModal from '../ClearCartModal/ClearCartModal';

type PropTypes = {
  products?: IKexVariation[];
  isLoading?: boolean;
  allowEdit?: boolean;
  isMiniCart?: boolean;
  openMiniCart: () => void;
  showClearCartModal: boolean;
  toggleClearCartModal: (state: boolean) => void;
};

function CartProductList({
  products,
  isLoading,
  openMiniCart,
  showClearCartModal,
  toggleClearCartModal,
  allowEdit = true,
  isMiniCart = true,
}: PropTypes) {
  const { 'common/loading': loading, 'common/clearCart': clearCartText } =
    useTranslationData();
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);
  const { channelId } = useCurrentPage<PageModelBase>();

  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });

  const renderContent = () => {
    return products?.map((product) =>
      isMobile || isMiniCart ? (
        <CartItemMobile
          key={product.code}
          product={product}
          allowEdit={allowEdit}
          litiumContext={litiumContext}
        />
      ) : (
        <CartItem
          key={product.code}
          product={product}
          allowEdit={allowEdit}
          litiumContext={litiumContext}
        />
      )
    );
  };

  return isLoading ? (
    <p>{loading}</p>
  ) : isMiniCart ? (
    <StyledMiniCart>
      {renderContent()}
      <TopClearCart>
        <button
          onClick={() => {
            toggleClearCartModal(true);
          }}
        >
          <IconWrapper>
            <TrashClearCartIcon size="clearCartTrashCan" />
          </IconWrapper>
          <span>{clearCartText}</span>
        </button>
      </TopClearCart>
      {showClearCartModal && (
        <ClearCartModal
          overlay={true}
          toggle={() => {
            toggleClearCartModal(false);
          }}
          clearCart={true}
        />
      )}
    </StyledMiniCart>
  ) : (
    <StyledProductList>{renderContent()}</StyledProductList>
  );
}

const StyledMiniCart = styled('div', {
  h: '100%',
  p: 4,
  overflowY: 'auto',
  '&::scrollbar': {
    display: 'none',
  },
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

const StyledProductList = styled('div', {});

const IconWrapper = styled('div');

const TopClearCart = styled('div', {
  display: 'flex',
  paddingBottom: '20px',
  mb: 6,
  justifyContent: 'flex-start',
  span: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '1px',
  },
  borderBottom: '1px solid $grey100',
  svg: {
    ml: 0,
    mr: 2,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    span: {
      position: 'relative',
      top: '2px',
      textTransform: 'uppercase',
    },
  },
});

export default CartProductList;
