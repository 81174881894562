import { ReactElement } from 'react';
import PriceInfo from '../../Molecules/PriceInfo/PriceInfo';
import IKexVariation from '../../KexVariation/Models/VariationModel.interface';
import KexLink from '../../Kex/KexLink';
import { RemoveFromCart } from '../../Pages/CartPage/Cart';
import ConditionalWrapper from '../../Shared/Common/ConditionalWrapper';
import { TrashIcon } from '../../Atoms/Icons';
import { styled } from '../../stitches.config';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import CartItemQuantitySelector from '../../Atoms/Quantity/CartItemQuantitySelector';
import Image from '../../Atoms/Image/Image';

type PropTypes = {
  product: IKexVariation;
  litiumContext: string;
  allowEdit?: boolean;
};

function CartItem({ product, allowEdit = false, litiumContext }: PropTypes) {
  const {
    'productItem/size': size,
    'productItem/remove': removeText,
    'productItem/notInStock': notInStockLabel,
  } = useTranslationData();

  const { languageRoute } = useAppSettingsData();

  return (
    <ConditionalWrapper
      condition={!allowEdit}
      wrapper={(children: ReactElement) => (
        <KexLink href={product.to} noUnderline={true}>
          {children}
        </KexLink>
      )}
    >
      <Item>
        <ConditionalWrapper
          condition={true}
          wrapper={(children: ReactElement) => (
            <KexLink href={product.to}>{children}</KexLink>
          )}
        >
          <ImageContainer>
            {product.image && (
              <StyledImage src={product.image.src} alt={product.image.alt} />
            )}
          </ImageContainer>
        </ConditionalWrapper>
        <Info>
          <Details>
            <ConditionalWrapper
              condition={allowEdit}
              wrapper={(children: ReactElement) => (
                <StyledKexLink href={product.to}>{children}</StyledKexLink>
              )}
            >
              <Title>{product.name}</Title>
            </ConditionalWrapper>
            {!product.inStock && !product.hideStockStatus && (
              <DetailText>{notInStockLabel}</DetailText>
            )}
            {allowEdit && (
              <Actions>
                <ActionButton
                  onClick={() =>
                    RemoveFromCart(product.code, languageRoute, litiumContext)
                  }
                >
                  <TrashIcon size="s" css={IconOverride} />
                  <ButtonText>{removeText}</ButtonText>
                </ActionButton>
              </Actions>
            )}
          </Details>
          {allowEdit && (
            <RightSection>
              {
                <CartItemQuantitySelector
                  lineItem={product}
                  canUpdateCart={true}
                />
              }
              {product.priceModel &&
                (product.priceModel.campaignPrice?.priceFormatted ? (
                  <PriceInfo
                    price={product.priceModel.campaignPrice?.priceFormatted}
                    justifyRight={true}
                  />
                ) : (
                  <PriceInfo
                    price={product.priceModel.price?.priceFormatted}
                    justifyRight={true}
                  />
                ))}
            </RightSection>
          )}
        </Info>
      </Item>
    </ConditionalWrapper>
  );
}

const StyledKexLink = styled(KexLink, {
  lineHeight: 1,
  mb: 2,
});

const Item = styled('div', {
  display: 'flex',
  borderBottom: '1px solid $primary',
  py: 4,
  lineHeight: '1',
  '&::not(:last-of-type)::after': {
    content: '""',
    position: 'absolute',
    w: '100%',
    mx: 'auto',
    my: 0,
    display: 'block',
    backgroundColor: '$cartItemDesktopBackground',
    h: 0.25,
    bottom: 0,
    opacity: 0.5,
  },
});

const ImageContainer = styled('div', {
  maxW: 16,
  w: '100%',
  h: 23,
});

const Actions = styled('div', {
  display: 'flex',
  mt: 'auto',
});

const RightSection = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const StyledImage = styled(Image, {
  objectFit: 'contain',
  wh: '100%',
});

const Info = styled('div', {
  display: 'flex',
  w: '100%',
  '@mediaMinMedium': {
    justifyContent: 'space-between',
  },
});

const Title = styled('span', {
  fs: 7,
  fontWeight: '$fw700',
  mb: 2,
  ls: '$ls07',
  color: '$cartItemProductTextPrimary',
});

const Details = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@mediaMinMedium': {
    ml: 4,
  },
  ml: 2,
});

const DetailText = styled('span', {
  fs: 6,
  color: '$cartItemProductTextPrimary',
  mb: 2,
  '&::last-of-type': {
    mb: 2,
  },
});

const ActionButton = styled('div', {
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  '@mediaMinMedium': {
    mr: 4,
  },
  mx: 0,
  my: 0,
});

const ButtonText = styled('span', {
  fs: 6,
  pb: 0.25,
  '&::hover': {
    borderBottom: '1px solid $borderBlack',
  },
});

const IconOverride = {
  mr: 2,
};

export default CartItem;
