import IKexVariation from '../../KexVariation/Models/VariationModel.interface';
import { styled } from '../../stitches.config';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import CartItemQuantitySelector from '../../Atoms/Quantity/CartItemQuantitySelector';
import { RemoveFromCart } from '../../Pages/CartPage/Cart';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { TrashIcon } from '../../Atoms/Icons';

type PropTypes = {
  product: IKexVariation;
  litiumContext: string;
  allowEdit?: boolean;
};

function CartItemMobile({
  product,
  allowEdit = false,
  litiumContext,
}: PropTypes) {
  const {
    'productItem/size': size,
    'productItem/notInStock': notInStockLabel,
  } = useTranslationData();
  const { languageRoute } = useAppSettingsData();

  return (
    <Item>
      <Row>
        <ImageContainer href={product.to}>
          {product.image && (
            <Image src={product.image.src} alt={product.image.alt} />
          )}
        </ImageContainer>
        <Column>
          {product.brand && <Brand>{product.brand}</Brand>}
          <ColumnTitle>{product.name}</ColumnTitle>
          {!product.inStock && !product.hideStockStatus && (
            <ColumnText error={true}>{notInStockLabel}</ColumnText>
          )}
          <ColumnText>
            {product.priceModel &&
              (product.priceModel.campaignPrice?.priceFormatted ? (
                <span>{product.priceModel.campaignPrice?.priceFormatted}</span>
              ) : (
                <span>{product.priceModel.price?.priceFormatted}</span>
              ))}
          </ColumnText>
          {allowEdit && (
            <QuantitySelectorWrapper>
              <CartItemQuantitySelector lineItem={product} />
            </QuantitySelectorWrapper>
          )}
        </Column>
        <IconWrapper
          onClick={() =>
            RemoveFromCart(product.code, languageRoute, litiumContext)
          }
        >
          <TrashIcon size="trashCan" />
        </IconWrapper>
      </Row>
      <Row></Row>
    </Item>
  );
}

const IconWrapper = styled('button', {
  alignSelf: 'start',
});

const Item = styled('div', {
  position: 'relative',
  pb: 6,
  mb: 4,
  '&::not(:last-of-type)::after': {
    content: '""',
    position: 'absolute',
    w: '100%',
    my: 0,
    mx: 'auto',
    display: 'block',
    backgroundColor: '$cartItemBackgroundPrimary',
    h: 0.25,
    bottom: 0,
    opacity: 0.5,
  },
  flexDirection: 'row',
  lineHeight: '$lh15',
  borderBottom: '1px solid $grey100',
});

const ImageContainer = styled('a', {
  br: 2,
  maxW: 24.5,
  h: 24.5,
  w: '100%',
  mr: 4,
  overflow: 'hidden',
});

const Image = styled('img', {
  objectFit: 'contain',
  w: '100%',
  h: '100%',
});

const QuantitySelectorWrapper = styled('div', {
  mt: 4,
});

const Column = styled('div', {
  display: 'flex',
  flexBasis: '100%',
  flexDirection: 'column',
  fs: 5,
  lineHeight: '16px',
  rowGap: '2px',
  variants: {
    flexEnd: {
      true: {
        justifyContent: 'flex-end',
      },
    },
  },
});

const Brand = styled('span', {
  fontSize: '9px',
});

const Row = styled('div', {
  display: 'flex',
});

const ColumnTitle = styled('span', {
  fontWeight: '$fw600',
  mb: '2px',
  ls: '$ls07',
  color: '$cartItemProductTextPrimary',
});

const ColumnText = styled('span', {
  color: '$cartItemProductTextPrimary',
  fs: 5.5,
  variants: {
    error: {
      true: {
        color: '$errorText',
      },
    },
  },
});

export default CartItemMobile;
